<template>
    <DefaultModalForm :open="open" :setOpen="setOpen" :action="updateUser">
        <div class="mt-3 text-center">
            <DialogTitle as="h3" class="font-semibold leading-6 text-gray-900 text-24">
                Modifier l'objectif mensuel
            </DialogTitle>
            <div class="w-full py-7">
                <label for="email-address" class="block text-sm font-medium text-left text-gray-700">
                    Valeur de l'objectif mensuel
                </label>
                <div class="mt-2">
                    <input type="number" name="nom" autocomplete="off" v-model="valueInput"
                        class="block w-full border-gray-300 rounded-md shadow-sm focus:border-purple-500 focus:ring-purple-500 text-10" />
                </div>
            </div>
        </div>
    </DefaultModalForm>
    <div v-if="benefitMonth && valueObjectif && benefitMonth.length > 0"
        class="flex flex-col justify-between mb-3 space-x-3">
        <div class="flex justify-between">
            <HeaderIconTextPart title="Votre chiffre d'affaire" :subtitle="`en ${monthLetter(benefitMonth[0].month)}`"
                :icon="PresentationChartLineIcon" />
            <button @click="setOpen(true)"
                class="xl:px-3 xl:py-2 px-1 py-0 text-sm font-semibold bg-transparent border rounded-md hover:bg-c_gray border-slate-800 text-slate-800">Modifier
                l'objectif</button>
        </div>
        <div v-if="isLargeScreen" class="flex flex-col h-full mt-3 space-y-5">
            <div class="flex flex-col space-y-3">
                <div class="flex flex-col items-baseline space-y-3 xl:flex-row xl:space-x-3">
                    <p class="font-semibold xl:text-42 text-36 text-slate-800">{{
                        numberSapce(roundNumbers(benefitMonth[0].price_value)) }} €
                    </p>
                    <p class="text-12 text-slate-600">soit {{ calculeValuePourcentage(Number(valueObjectif),
                        benefitMonth[0].price_value) }}% de votre objectif de {{
                            numberSapce(valueObjectif) }}€</p>
                </div>
                <div class="flex w-full h-2 space-x-1">
                    <div :style="`width: ${calculeValuePourcentage(Number(valueObjectif),
                        benefitMonth[0].price_value)}%`" class="h-full rounded-full bg-c_green" />
                    <div :style="`width: ${100 - calculeValuePourcentage(Number(valueObjectif),
                        benefitMonth[0].price_value)}%`" class="h-full rounded-full bg-slate-600" />
                </div>
            </div>
            <div class="flex flex-col space-y-3">
                <div class="flex items-baseline space-x-3">
                    <p class="text-12 text-slate-600">Répartition par catégorie</p>
                </div>
                <div class="flex w-full space-x-1">
                    <div v-for="(item, index) in categoryMonth" :key="index" class="min-w-[10%]"
                        :style="`width: ${calculeValuePourcentage(totalPriceCategory, item.price_value)}%`">
                        <div :class="[colors[index + 1], 'rounded-full bg-gray-100 w-full h-2']" />
                        <div class="flex w-full space-x-1">
                            <div class="w-full h-full">
                                <div class="flex items-baseline space-x-3">
                                    <div :class="[colors[index + 1], 'w-2 h-2 bg-gray-100 rounded-full']" />
                                    <div class="flex flex-col ">
                                        <p class="font-semibold text-12 text-slate-600">{{ item.name }}</p>
                                        <p class="text-12 text-slate-700">{{ item.price_value }} € /
                                            {{ calculeValuePourcentage(totalPriceCategory, item.price_value) }}%
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <DoughnutTextChart :benefitMonth="benefitMonth" :categoryMonth="categoryMonth" v-else />
    </div>
    <DefaultBox v-else customClass="h-[300px] w-full flex flex-col justify-center items-center">
        <p class="font-semibold text-center text-24">Aucune donnée pour le moment</p>
    </DefaultBox>
</template>

<script setup>
import { defineProps, computed, ref } from 'vue'

import HeaderIconTextPart from '@/components/Header/HeaderIconTextPart.vue'
import { calculeValuePourcentage, totalArray } from '@/utils/UtilsCacul';
import { monthLetter } from '@/utils/DataFormater';
import { roundNumbers } from '@/utils/RoundNumbers';
import { PresentationChartLineIcon } from '@heroicons/vue/20/solid'
import DefaultModalForm from "@/components/Modal/DefaultModalForm.vue"
import { UpdateUser } from '@/api/services/UserServiceFunctions';
import authService from "@/api/services/authService";
import { numberSapce } from '@/utils/DataFormater';
import DefaultBox from '@/components/Layout/DefaultBox.vue';
import DoughnutTextChart from '@/components/Chart/DoughnutTextChart.vue';
import { useScreenStore } from '@/stores/useScreenStore'

const screenStore = useScreenStore();

const colors = {
    1: '!bg-c_green',
    2: '!bg-c_purple',
    3: '!bg-c_light_bleu',
    4: '!bg-amber-500',
}

const open = ref(false)
const valueObjectif = ref(localStorage.getItem("objectifValue") || 1000)
const valueInput = ref(Number(localStorage.getItem("objectifValue") || null))

screenStore.initializeScreenSizeListener();

const isLargeScreen = screenStore.isLargeScreen;

const setOpen = (value) => {
    open.value = value;
}

const props = defineProps({
    benefitMonth: Array,
    categoryMonth: Array
})

const totalPriceCategory = computed(() =>
    totalArray(props.categoryMonth, 'price_value')
);

const updateUser = () => {
    try {
        UpdateUser(authService.getUser().id, {
            objectifValue: valueInput.value,
        })
        localStorage.setItem("objectifValue", valueInput.value);
        valueObjectif.value = valueInput.value
        open.value = false
    } catch (error) {
        console.error(error)
    }
}
</script>