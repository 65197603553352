<template>
  <h2 class="font-bold tracking-tight text-gray-900 text-22">Liste des plugins</h2>
  <DefaultBox>
      <Combobox v-slot="{ activeOption }" @update:modelValue="onSelect">
        <div class="relative">
          <MagnifyingGlassIcon class="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400" aria-hidden="true" />
          <ComboboxInput class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm" placeholder="Chercher..." @change="query = $event.target.value" @blur="query = ''" />
        </div>

        <ComboboxOptions v-if="query === '' || filteredPeople.length > 0" class="flex transform-gpu divide-x divide-gray-100" as="div" static hold>
          <div :class="['max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4', activeOption && 'sm:h-96']">
            <div hold class="-mx-2 text-sm text-gray-700">
              <ComboboxOption
                  v-for="person in query === '' ? recent.filter(Boolean) : filteredPeople.filter(Boolean)"
                  :key="person.id"
                  :value="person"
                  as="template"
                  v-slot="{ active }"
              >
                <div :class="['group flex cursor-default select-none items-center rounded-md p-2', active && 'bg-gray-100 text-gray-900']">
                  <img :src="person.imageUrl" alt="" class="h-6 w-6 flex-none rounded-full" />
                  <span class="ml-3 flex-auto truncate">{{ person.name }}</span>
                  <ChevronRightIcon v-if="active" class="ml-3 h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                </div>
              </ComboboxOption>
            </div>
          </div>

          <div v-if="activeOption" class="hidden h-96 w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex">
            <div class="flex-none p-6 text-center">
              <img :src="activeOption.imageUrl" alt="" class="mx-auto h-24 w-24 rounded-lg" />
              <h2 class="mt-3 font-semibold text-gray-900">
                {{ activeOption.name }}
              </h2>
              <p class="text-sm leading-6 text-gray-500">{{ activeOption.desc }}</p>
            </div>
            <div class="flex flex-auto flex-col justify-between p-6">
              <dl class="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">
                <dd>{{ activeOption.l1 }}</dd>
                <dd class="truncate">
                    {{ activeOption.l2 }}
                </dd>
                <dd class="truncate">
                    {{ activeOption.l3 }}
                </dd>
              </dl>
              <button type="button" class="mt-6 w-full rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Activer le plugin</button>
            </div>
          </div>
        </ComboboxOptions>

        <div v-if="query !== '' && filteredPeople.length === 0" class="px-6 py-14 text-center text-sm sm:px-14">
          <UsersIcon class="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
          <p class="mt-4 font-semibold text-gray-900">Aucun plugin trouvé</p>
          <p class="mt-2 text-gray-500"></p>
        </div>
      </Combobox>
  </DefaultBox>
</template>

<script setup>
import { computed, ref } from 'vue'
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid'
import { ChevronRightIcon, UsersIcon } from '@heroicons/vue/24/outline'
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption
} from '@headlessui/vue'

import DefaultBox from '@/components/Layout/DefaultBox.vue'


// Sample people array
const people = [
  {
    id: 1,
    name: 'Plugin Shopify',
    desc: 'En cours de développement',
    l1: '',
    l2: '',
    l3: '',
    imageUrl:
        'https://cdn.worldvectorlogo.com/logos/shopify.svg',
  },
  {
    id: 2,
    name: 'Plugin Woocommerce',
    desc: 'En cours de développement',
    l1: '',
    l2: '',
    l3: '',
    imageUrl:
        'https://connectif.ai/wp-content/uploads/2023/07/woocommerce.png',
  },
]

// Recent searches
const recent = people.slice(0, 3)

const query = ref('')
const filteredPeople = computed(() =>
    query.value === ''
        ? []
        : people.filter((person) => {
          return person.name.toLowerCase().includes(query.value.toLowerCase())
        }),
)

function onSelect(person) {
  if (person) {
    window.location = person.url
  }
}
</script>
