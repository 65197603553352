<script setup>

</script>

<template>
  <p>2</p>
</template>

<style scoped>

</style>