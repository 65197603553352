<template>
  <vue-tailwind-datepicker
      i18n="fr"
      :auto-apply="false"
      :options="options"
      v-model="dateValue"
      :formatter="formatter"
  />
</template>

<script setup>
import {ref, defineEmits, watch} from "vue";
import VueTailwindDatepicker from "vue-tailwind-datepicker";
import {formatDateTime} from "@/utils/DateSelectData";

const emit = defineEmits(['dates']);

const dateValue = ref([]);
const formatter = ref({
  date: 'DD MMM YYYY',
  month: 'MMM',
});
const options = ref({
  shortcuts: {
    today: "Aujourd'hui",
    yesterday: "Hier",
    past: (period) => `Les ${period} derniers jours`,
    currentMonth: "Ce mois-ci",
    pastMonth: "Le mois dernier",
  },
  footer: {
    apply: "Appliquer",
    cancel: "Annuler",
  },
});

watch(dateValue, (newValue) => {
  if (newValue.length === 2) {
    const formattedDates = newValue.map(date => formatDateTime(date));
    emit('dates', formattedDates);
  }
});
</script>
