<template>
  <div class="bg-c_light_gray">
    <Toaster position="top-right" />
    <RouterView />

    <!-- Notification de mise à jour disponible -->
    <div v-if="updateAvailable"
      class="fixed bottom-4 right-4 bg-c_gray text-slate-900 p-4 rounded-lg shadow-lg animate-fade-in">
      <p>Une nouvelle version est disponible !</p>
      <button @click="refreshApp" class="mt-2 bg-slate-900 text-c_gray font-bold py-1 px-3 rounded">
        Mettre à jour
      </button>
    </div>

    <!-- Modale pour Android -->
    <div v-if="showInstallPrompt && isAndroid"
      class="fixed z-50 inset-0 flex items-center justify-center bg-black bg-opacity-50 animate-fade-in">
      <div class="bg-white p-6 rounded-xl shadow-2xl transform transition-all duration-300 ease-in-out max-w-sm mx-4">
        <h2 class="text-2xl font-semibold text-center text-gray-800">Installer l'application</h2>
        <p class="mt-3 text-gray-600 text-center">Appuyez sur les trois points en haut à droite de votre navigateur,
          puis sélectionnez "Ajouter à l'écran d'accueil".</p>
        <div class="mt-5 flex justify-center">
          <button @click="showInstallPrompt = false"
            class="bg-slate-900 text-white font-bold py-2 px-6 rounded-full shadow-lg hover:shadow-xl transform hover:scale-105 transition-transform">Fermer</button>
        </div>
      </div>
    </div>

    <!-- Modale pour iOS -->
    <div v-if="showInstallPrompt && isIos"
      class="fixed z-50 inset-0 flex items-center justify-center bg-black bg-opacity-50 animate-fade-in">
      <div class="bg-white p-6 rounded-xl shadow-2xl transform transition-all duration-300 ease-in-out max-w-sm mx-4">
        <h2 class="text-2xl font-semibold text-center text-gray-800">Installer l'application</h2>
        <p class="mt-3 text-gray-600 text-center">Appuyez sur l'icône de partage en bas de votre navigateur, puis
          sélectionnez "Ajouter à l'écran d'accueil".</p>
        <div class="mt-5 flex justify-center">
          <button @click="showInstallPrompt = false"
            class="bg-slate-900 text-white font-bold py-2 px-6 rounded-full shadow-lg hover:shadow-xl transform hover:scale-105 transition-transform">Fermer</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue'
import { Toaster } from 'vue-sonner'
import { RouterView } from 'vue-router'
import { register } from 'register-service-worker'

const showInstallPrompt = ref(false)
const isAndroid = ref(false)
const isIos = ref(false)
const isStandalone = ref(false)
const updateAvailable = ref(false)
const registration = ref(null)

// Access the global $workbox instance if needed (from your previous code)
const { appContext } = getCurrentInstance();
const wb = appContext.config.globalProperties.$workbox;

// Handle service worker updates
onMounted(() => {
  if (wb) {
    wb.addEventListener('waiting', () => {
      updateAvailable.value = true;
      registration.value = wb;
    });
  }

  document.addEventListener('swUpdated', (event) => {
    const registration = event.detail;
    if (registration.waiting) {
      updateAvailable.value = true;
      registration.value = registration;
      console.log('A new version of the app is available.');
    }
  });
});

// Handle platform detection and PWA installation prompts
onMounted(() => {
  const userAgent = window.navigator.userAgent.toLowerCase()
  isAndroid.value = userAgent.includes('android')
  isIos.value = /iphone|ipad|ipod/.test(userAgent)

  // Detect standalone mode
  if (window.matchMedia('(display-mode: standalone)').matches || navigator.standalone) {
    isStandalone.value = true
  }

  if (!isStandalone.value && process.env.NODE_ENV === "production") {
    // Show installation prompt after 3 seconds if not in standalone mode
    setTimeout(() => {
      showInstallPrompt.value = true
    }, 3000)
  }

  // Register service worker
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log('App is being served from cache by a service worker.')
    },
    registered() {
      console.log('Service worker has been registered.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated(reg) {
      console.log('New content is available; please refresh.')
      updateAvailable.value = true
      registration.value = reg
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    },
  })
});

// Function to refresh the app when a new version is available
const refreshApp = () => {
  if (registration.value && registration.value.waiting) {
    registration.value.waiting.postMessage({ type: 'SKIP_WAITING' });
    registration.value.waiting.addEventListener('statechange', (e) => {
      if (e.target.state === 'activated') {
        window.location.reload();
      }
    });
  }
};
</script>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

/* these are necessary styles for vue flow */
@import '@vue-flow/core/dist/style.css';

/* this contains the default theme, these are optional styles */
@import '@vue-flow/core/dist/theme-default.css';

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fade-in 0.5s ease-in-out;
}

@import 'https://cdn.jsdelivr.net/npm/@vue-flow/core@1.41.2/dist/style.css';
@import 'https://cdn.jsdelivr.net/npm/@vue-flow/core@1.41.2/dist/theme-default.css';
@import 'https://cdn.jsdelivr.net/npm/@vue-flow/controls@latest/dist/style.css';
@import 'https://cdn.jsdelivr.net/npm/@vue-flow/minimap@latest/dist/style.css';
@import 'https://cdn.jsdelivr.net/npm/@vue-flow/node-resizer@latest/dist/style.css';

html,
body,
#app {
  margin: 0;
  height: 100%;
}

.vue-flow__minimap {
  transform: scale(75%);
  transform-origin: bottom right;
}

.dnd-flow {
    flex-direction:column;
    display:flex;
    height:100%
}

.dnd-flow aside {
    color:#fff;
    font-weight:700;
    border-right:1px solid #eee;
    padding:15px 10px;
    font-size:12px;
    background:#10b981bf;
    -webkit-box-shadow:0px 5px 10px 0px rgba(0,0,0,.3);
    box-shadow:0 5px 10px #0000004d
}

.dnd-flow aside .nodes>* {
    margin-bottom:10px;
    cursor:grab;
    font-weight:500;
    -webkit-box-shadow:5px 5px 10px 2px rgba(0,0,0,.25);
    box-shadow:5px 5px 10px 2px #00000040
}

.dnd-flow aside .description {
    margin-bottom:10px
}

.dnd-flow .vue-flow-wrapper {
    flex-grow:1;
    height:100%
}

@media screen and (min-width: 640px) {
    .dnd-flow {
    flex-direction:row
}

.dnd-flow aside {
    min-width:25%
}


}

@media screen and (max-width: 639px) {
    .dnd-flow aside .nodes {
    display:flex;
    flex-direction:row;
    gap:5px
}


}

.dropzone-background {
    position:relative;
    height:100%;
    width:100%
}

.dropzone-background .overlay {
    position:absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    z-index:1;
    pointer-events:none
}
</style>
