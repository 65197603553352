<template>
  <NewUserModal v-if="valueObjectif === 'null' || valueObjectif === '0'" />
  <DefaultAppLayout>
    <div class="flex flex-col pb-32 xl:pr-3 space-y-3 xl:pb-0">
      <StatsDash :benefitMonth="benefitMonth" :categoryMonth="categoryMonth" />
      <div class="flex flex-col items-baseline space-y-3 xl:flex-row xl:space-x-3">
        <VentesDash :salesData="salesData" />
        <DefaultBox customClass="xl:w-1/2 w-full h-[400px] flex flex-col justify-between">
          <HeaderArrowNext title="Vos 5 meilleurs canaux" subtitle="" to="/clients" :icon="UsersIcon"
            isArrow={false} />
          <SourceTable v-if="canalMonth != null" :canalMonth="canalMonth" />
          <div v-else class="flex items-center justify-center h-full">
            <p class="font-semibold text-center text-24">Aucune donnée pour le moment</p>
          </div>
        </DefaultBox>
      </div>
      <DefaultBox v-if="chartData != null" customClass="w-full">
        <div class="flex flex-col justify-between h-full mb-3 space-x-2">
          <HeaderArrowNext title="Évolution de vos revenus" subtitle="sur les 5 derniers mois" :to="'/'"
            :icon="PresentationChartLineIcon" />
          <div class="h-[350px] mt-3">
            <LineStyledChart :chartData="chartData" />
          </div>
        </div>
      </DefaultBox>
      <DefaultBox v-else customClass="h-[300px] w-full flex flex-col justify-center items-center">
        <p class="font-semibold text-center text-24">Aucune donnée pour le moment</p>
      </DefaultBox>
    </div>
  </DefaultAppLayout>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { toast } from "vue-sonner";

import DefaultAppLayout from '@/components/Layout/DefaultAppLayout.vue'
import DefaultBox from '@/components/Layout/DefaultBox.vue'
import HeaderArrowNext from '@/components/Header/HeaderArrowNext.vue'
import StatsDash from '@/views/private/Dashboard/components/StatsDash.vue';
import VentesDash from '@/views/private/Dashboard/components/VentesDash.vue';
import SourceTable from '@/components/Table/Other/SourceTable.vue'
import LineStyledChart from '@/components/Chart/LineStyledChart.vue'
import NewUserModal from '@/components/Other/NewUserModal/NewUserModal.vue'

import { monthLetter } from '@/utils/DataFormater';
import { roundNumbers } from '@/utils/RoundNumbers';

import { GetAllViewBenefitMonth, GetAllViewCanalMonth, GetAllViewBenefitMonthCategory } from '@/api/services/StatViewService'
import { GetAllSaleData } from '@/api/services/SalesServiceFunctions'
import { nowYears, nowMonth } from '@/data/dateData'

import {
  UsersIcon,
  PresentationChartLineIcon
} from '@heroicons/vue/24/solid';

const benefitMonth = ref(null);
const canalMonth = ref(null);
const categoryMonth = ref(null);
const salesData = ref(null);

const chartData = ref(null);

const valueObjectif = ref(localStorage.getItem("objectifValue"))

const getData = async () => {
  try {

    // Call
    salesData.value = await GetAllSaleData("itemsPerPage=4&page=1&order%5BcreatedAt%5D=desc");
    canalMonth.value = await GetAllViewCanalMonth({
      order: { 'price_value': 'desc' },
      month: nowMonth,
      years: nowYears,
      itemsPerPage: 5,
      page: 1
    });

    categoryMonth.value = await GetAllViewBenefitMonthCategory({
      order: { 'price_value': 'desc' },
      month: nowMonth,
      years: nowYears,
      itemsPerPage: 4,
      page: 1
    });

    console.log("categoryMonth.value", categoryMonth.value);

    const benefitDatas = await GetAllViewBenefitMonth({
      order: { 'date_full': 'desc' },
      years: nowYears,
    });

    chartData.value = {
      labels: benefitDatas.map((item) => monthLetter(item.month)),
      datasets: [
        {
          label: "Chiffre d'affaire",
          data: benefitDatas.map((item) => roundNumbers(item.price_value)),
          backgroundColor: "#E1BEF6",
          borderColor: "#E1BEF6",
          borderWidth: 2,
          fill: false,
          tension: 0.4
        },
        {
          label: "Bénéfice",
          data: benefitDatas.map((item) => roundNumbers(item.benefit_value)),
          backgroundColor: "#E1FF72",
          borderColor: "#E1FF72",
          borderWidth: 2,
          fill: false,
          tension: 0.4
        },
      ],
    }


    benefitMonth.value = benefitDatas.slice(0, 2)
  } catch (error) {
    console.error(error);
    toast.error("Une erreur est survenue");
  }
}

onMounted(() => {
  getData()
})
</script>