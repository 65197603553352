<template>
  <div
      class="px-6 py-24 sm:py-32 lg:px-8">
    <div class="mx-auto max-w-2xl text-center">
      <h2 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Fonctionnalité en développement</h2>
      <p class="mt-6 text-lg leading-8 text-gray-600">
        Le rapport détaillé est actuellement en cours de développement pendant l'ALFAT. Si vous avez des questions ou
        des suggestions, n'hésitez pas à nous contacter à <a href="mailto:contact@maker-copilot.com"
                                                             class="text-indigo-600 underline">contact@maker-copilot.com</a>.
      </p>
    </div>
  </div>
</template>