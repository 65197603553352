import dayjs from "dayjs";

export const months = [
    { id: 1, name: 'Janvier' },
    { id: 2, name: 'Février' },
    { id: 3, name: 'Mars' },
    { id: 4, name: 'Avril' },
    { id: 5, name: 'Mai' },
    { id: 6, name: 'Juin' },
    { id: 7, name: 'Juillet' },
    { id: 8, name: 'Août' },
    { id: 9, name: 'Septembre' },
    { id: 10, name: 'Octobre' },
    { id: 11, name: 'Novembre' },
    { id: 12, name: 'Décembre' },
]
// Fonction pour générer un tableau d'années
function generateYears(startYear, endYear) {
    const years = [];
    for (let year = startYear; year >= endYear; year--) {
        years.push({ name: year });
    }
    return years;
}

// Définir la plage d'années
const startYear = new Date().getFullYear(); // Année actuelle
const endYear = 2019; // Année de fin souhaitée

// Générer le tableau des années
export const years = generateYears(startYear, endYear);

export const formatDateTime = (date) => {
    return dayjs(date).format('DD-MM-YYYY HH:mm:ss');
};