<template>
    <DefaultAppLayout>
        <div class="p-5">
            <div class="flex items-center justify-between">
                <h2 class="font-bold tracking-tight text-gray-900 text-22">Toutes vos ventes</h2>
                <DefaultLinkBtn to="/create-sale" title="Créer une vente" />
            </div>
            <div class="flex flex-col xl:flex-row xl:items-center xl:justify-between xl:space-x-3">
                <input type="text" v-model="query"
                    class="w-full mt-3 rounded-md border-0 bg-c_smoke_white px-4 py-2.5 text-gray-900 focus:ring-0 text-lg"
                    placeholder="Rechercher une vente..." />

                <select v-model="selectedCanal" class="w-full mt-3 rounded-md border-gray-300 px-4 py-2.5 text-lg">
                    <option value="">Tous les canaux de vente</option>
                    <option v-for="canal in canals" :key="canal.id" :value="canal.name">
                        {{ canal.name }}
                    </option>
                </select>
            </div>

            <DefaultBox customClass="mt-6 overflow-hidden border-t border-gray-100">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    <th scope="col"
                                        class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-12 font-semibold text-gray-900 sm:pl-0">
                                        ID Ventes</th>
                                    <th scope="col" v-if="isLargeScreen"
                                        class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-12 font-semibold text-gray-900 sm:pl-0">
                                        Date</th>
                                    <th scope="col"
                                        class="whitespace-nowrap px-2 py-3.5 text-left text-12 font-semibold text-gray-900">
                                        Canal de vente</th>
                                    <th scope="col" v-if="isLargeScreen"
                                        class="whitespace-nowrap px-2 py-3.5 text-left text-12 font-semibold text-gray-900">
                                        Prix</th>
                                    <th scope="col" v-if="isLargeScreen"
                                        class="whitespace-nowrap px-2 py-3.5 text-left text-12 font-semibold text-gray-900">
                                        Bénéfice</th>
                                    <th scope="col" v-if="isLargeScreen"
                                        class="whitespace-nowrap px-2 py-3.5 text-left text-12 font-semibold text-gray-900">
                                        Pourcentage bénéfice</th>
                                    <th scope="col" v-if="isLargeScreen"
                                        class="whitespace-nowrap px-2 py-3.5 text-left text-12 font-semibold text-gray-900">
                                        Nombre de produits</th>
                                    <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-0">
                                        <span class="sr-only">Voir</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <tr v-for="sale in filteredSales" :key="sale.id">
                                    <td class="py-2 pl-4 pr-3 text-gray-500 whitespace-nowrap text-12 sm:pl-0">
                                        {{ sale.name }}
                                    </td>
                                    <td v-if="isLargeScreen"
                                        class="py-2 pl-4 pr-3 text-gray-500 whitespace-nowrap text-12 sm:pl-0">
                                        {{ new Date(sale.createdAt).toLocaleDateString("fr-FR") }}
                                    </td>
                                    <td class="px-2 py-2 font-medium text-gray-900 whitespace-nowrap text-12">
                                        {{ sale.canal.name }}
                                    </td>
                                    <td v-if="isLargeScreen" class="px-2 py-2 text-gray-500 whitespace-nowrap text-12">
                                        {{ sale.price }} €
                                    </td>
                                    <td v-if="isLargeScreen" class="px-2 py-2 text-gray-500 whitespace-nowrap text-12">
                                        {{ sale.benefit }}
                                    </td>
                                    <td v-if="isLargeScreen" class="px-2 py-2 text-gray-500 whitespace-nowrap text-12">
                                        {{ calculeValuePourcentage(sale.price, sale.benefit) }} %
                                    </td>
                                    <td v-if="isLargeScreen" class="px-2 py-2 text-gray-500 whitespace-nowrap text-12">
                                        {{ sale.nbProduct }}
                                    </td>
                                    <td
                                        class="relative py-2 pl-3 pr-4 font-medium text-right whitespace-nowrap text-12 sm:pr-0">
                                        <RouterLink :to="'/sale/' + sale.id"
                                            class="text-purple-600 hover:text-purple-900">
                                            Voir<span class="sr-only">, {{ sale.id }}</span>
                                        </RouterLink>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div v-if="query !== '' && filteredSales.length === 0" class="px-4 py-14 text-center sm:px-14">
                    <UsersIcon class="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                    <p class="mt-4 text-sm text-gray-900">Aucun élément trouvé..</p>
                </div>
            </DefaultBox>
          <div class="flex flex-1 justify-between items-center sm:justify-end mt-5">
            <button :disabled="nbrPage === 1" @click="paginationUpdateData(nbrPage - 1)" class="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0">Précédent</button>
            <p class="font-semibold text-gray-900 ml-3">{{nbrPage}}</p>
            <button @click="paginationUpdateData(nbrPage + 1)" class="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0">Suivant</button>
          </div>
        </div>
    </DefaultAppLayout>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { RouterLink } from 'vue-router';
import { calculeValuePourcentage } from '@/utils/UtilsCacul';
import DefaultAppLayout from '@/components/Layout/DefaultAppLayout.vue';
import DefaultBox from '@/components/Layout/DefaultBox.vue';
import DefaultLinkBtn from '@/components/Button/DefaultLinkBtn.vue';
import { GetAllSaleData } from '@/api/services/SalesServiceFunctions';
import { GetAllSalesChannelData } from '@/api/services/SalesChannelServiceFunctions';  // Assurez-vous d'importer les catégories
import { useScreenStore } from '@/stores/useScreenStore';

const screenStore = useScreenStore();

const sales = ref([]);
const canals = ref([]);  // Stocker les catégories ici
const selectedCanal = ref('');  // Stocker la catégorie sélectionnée
const query = ref('');
const nbrPage = ref(1);

// Initialiser la taille d'écran
screenStore.initializeScreenSizeListener();
const isLargeScreen = screenStore.isLargeScreen;

// Fonction pour récupérer les ventes et les catégories
const fetchSales = async () => {
    sales.value = await GetAllSaleData(`order%5BcreatedAt%5D=desc&itemsPerPage=30&page=${nbrPage.value}`);
    canals.value = await GetAllSalesChannelData();  // Récupérer les catégories
};

// Fonction de filtrage des ventes
const filteredSales = computed(() => {
    return sales.value.filter(sale => {
        const matchesQuery = query.value === '' || sale.name.toLowerCase().includes(query.value.toLowerCase());
        const matchesCanal = selectedCanal.value === '' || sale.canal?.name === selectedCanal.value;
        return matchesQuery && matchesCanal;
    });
});

const paginationUpdateData = (number) => {
  nbrPage.value = number;

  fetchSales();
}

// Charger les données des ventes et des catégories au montage
onMounted(() => {
    fetchSales();
});
</script>
