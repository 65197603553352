import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import wb from "./registerServiceWorker";

const app = createApp(App);
const pinia = createPinia();

// Set global properties in Vue 3
app.config.globalProperties.$workbox = wb;

app.use(pinia);
app.use(router);
app.mount('#app');
