<template>
  <DefaultAppLayout>
    <div class="flex flex-col lg:flex-row lg:space-x-2 space-y-2 lg:space-y-0 pt-5 lg:min-h-full">
      <div class="w-full lg:w-1/5 space-y-2">
        <DefaultBox>
          <nav class="ml-2 flex flex-1 flex-col w-full" aria-label="Sidebar">
            <ul role="list" class="-mx-2 space-y-1 w-full">
              <li v-for="(item, index) in navigation" :key="index" class="w-full">
                <button
                    @click="navigationTabs(index)"
                    :class="[item.current ? 'bg-gray-50 text-purple-600' : 'text-gray-700 hover:bg-gray-50 hover:text-purple-600', 'group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold w-full']">
                  <component :is="item.icon"
                             :class="[item.current ? 'text-purple-600' : 'text-gray-400 group-hover:text-purple-600', 'h-6 w-6 shrink-0']"
                             aria-hidden="true"/>
                  {{ item.name }}
                  <span v-if="item.count"
                        class="ml-auto w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs/5 font-medium text-gray-600 ring-1 ring-inset ring-gray-200"
                        aria-hidden="true">{{ item.count }}</span>
                </button>
              </li>
            </ul>
          </nav>
        </DefaultBox>
        <DefaultBox v-if="isLargeScreen">
          <p class="font-semibold text-14 text-slate-800">Dates d'analyse</p>
          <p class="text-12 text-slate-700">de {{ new Date(route.params.date1).toLocaleDateString("fr") }} au
            {{ new Date(route.params.date2).toLocaleDateString("fr") }}</p>
        </DefaultBox>
        <DefaultBox v-if="isLargeScreen">
          <p class="font-semibold text-14 text-slate-800">Analyse détaillée de vos ventes</p>
          <p class="text-12 text-slate-700">Sur cette page, vous trouverez une analyse complète de vos ventes produit
            par produit. Vous pouvez comparer facilement les données entre plusieurs dates et pour les périodes de votre
            choix. Grâce à notre intelligence artificielle, vous bénéficiez d'une analyse encore plus poussée, qui vous
            aide à mieux comprendre les tendances et à affiner votre stratégie de vente.</p>
        </DefaultBox>
      </div>
      <DefaultBox class="w-full lg:w-4/5 lg:min-h-[90vh]">
        <ResumeTabs v-if="navigation[0].current"/>
        <GlobalRevenuTabs v-if="navigation[1].current"/>
        <CanalTabs v-if="navigation[2].current"/>
        <ClientTabs v-if="navigation[3].current"/>
        <AnalyseTabs v-if="navigation[4].current"/>

      </DefaultBox>
    </div>
  </DefaultAppLayout>
</template>

<script setup>
import {ref} from 'vue'
import {useRoute} from 'vue-router'

import DefaultAppLayout from '@/components/Layout/DefaultAppLayout.vue';
import DefaultBox from "@/components/Layout/DefaultBox.vue";

import AnalyseTabs from "@/views/private/Rapport/components/tabs/AnalyseTabs.vue";
import CanalTabs from "@/views/private/Rapport/components/tabs/CanalTabs.vue";
import ClientTabs from "@/views/private/Rapport/components/tabs/ClientTabs.vue";
import GlobalRevenuTabs from "@/views/private/Rapport/components/tabs/GlobalRevenuTabs.vue";
import ResumeTabs from "@/views/private/Rapport/components/tabs/ResumeTabs.vue";
import {useScreenStore} from "@/stores/useScreenStore";

const screenStore = useScreenStore();

screenStore.initializeScreenSizeListener();

const route = useRoute()
const isLargeScreen = screenStore.isLargeScreen;

import {
  BuildingStorefrontIcon,
  ChartPieIcon,
  DocumentCurrencyEuroIcon,
  DocumentMagnifyingGlassIcon,
  UsersIcon,
} from '@heroicons/vue/24/outline'
import {GetRapportData} from "@/api/services/RapportServiceFunctions";

const navigation = ref([
  {name: 'Résumé', icon: ChartPieIcon, current: true},
  {name: 'Analyse des revenus global', icon: DocumentCurrencyEuroIcon, current: false},
  {name: 'Analyse de vos canaux de vente ', icon: BuildingStorefrontIcon, current: false},
  {name: 'Analyse de vos clients', icon: UsersIcon, current: false},
  {name: 'Notre analyse', count: 'Beta AI', icon: DocumentMagnifyingGlassIcon, current: false},
])

const getData = async () => {
  const type = route.params.type

  if (type === 1) {
    await GetRapportData(route.params.data1, route.params.data2, null, null)
  } else {
    await GetRapportData(route.params.data1, route.params.data2, route.params.data3, route.params.data4)
  }
}

const navigationTabs = (index) => {
  navigation.value.map((item, i) => {
    navigation.value[i].current = false
  })
  navigation.value[index].current = true;
}


getData()
</script>
