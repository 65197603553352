<template>
  <div v-if="products.length > 0" class="mt-4 bg-white border border-gray-200 rounded-lg shadow-sm">
    <ul role="list" class="divide-y divide-gray-200">
      <li v-for="product in products" :key="product.id" class="flex px-4 py-6 sm:px-6">
        <div class="flex flex-col flex-1 ml-6">
          <div class="flex">
            <div class="flex-1 min-w-0">
              <h4 class="text-sm">
                <p class="font-medium text-gray-700 hover:text-gray-800">{{ product.name }}</p>
              </h4>
              <div class="flex space-x-3">
                <p v-for="category in product.category" :key="category.id"
                   class="mt-1 text-sm text-gray-500">Catégories : {{ category.name }}</p>
              </div>
              <p class="mt-1 text-sm text-gray-500">Client : {{ product.client.name }}</p>
              <p class="mt-1 text-sm text-gray-500">Prix : {{ product.price.price }} €</p>
            </div>
            <div class="flex-shrink-0 flow-root ml-4">
              <button type="button"
                      class="-m-2.5 flex items-center justify-center bg-white p-2.5 text-gray-400 hover:text-red-600">
                <span class="sr-only">Supprimer</span>
                <TrashIcon @click="deleteProduct(product)" class="w-5 h-5" aria-hidden="true"/>
              </button>
            </div>
          </div>
          <div class="flex items-end justify-between flex-1 pt-2">
            <p class="mt-1 text-sm font-medium text-gray-900">{{ product.price.price }} €</p>
            <div class="ml-4">
              <label for="quantity" class="sr-only">Quantité</label>
              <select id="quantity" name="quantity" @change="addMultipleProduct($event, product)"
                      class="text-base font-medium text-left text-gray-700 border border-gray-300 rounded-md shadow-sm focus:border-purple-500 focus:outline-none focus:ring-1 focus:ring-purple-500 sm:text-sm">
                <option v-for="n in 100" :key="n" :value="n">{{ n }}</option>
              </select>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <dl class="px-4 py-6 space-y-6 border-t border-gray-200 sm:px-6">
      <div class="flex items-center justify-between">
        <dt class="text-sm">Nombre de produits</dt>
        <dd class="text-sm font-medium text-gray-900">{{ realProduct.length }}</dd>
      </div>
      <div class="flex items-center justify-between">
        <dt class="text-sm">Pourcentage de bénéfice</dt>
        <dd class="text-sm font-medium text-gray-900">{{ benefitPercentage }} %</dd>
      </div>
      <div class="flex items-center justify-between">
        <dt class="text-sm">Charges fixes</dt>
        <dd class="text-sm font-medium text-gray-900">{{ roundNumbers(totalExpense) }} €</dd>
      </div>
      <div class="flex items-center justify-between">
        <dt class="text-sm">Total commission</dt>
        <dd class="text-sm font-medium text-gray-900">{{ roundNumbers(commissionPercentage) }} €</dd>
      </div>
      <div class="flex items-center justify-between">
        <dt class="text-sm">Temps de production (en heures)</dt>
        <dd class="text-sm font-medium text-gray-900">{{ totalTime }} heure<span v-if="totalTime > 1">s</span>
        </dd>
      </div>
      <div class="flex items-center justify-between">
        <dt class="text-sm">Total URSSAF</dt>
        <dd class="text-sm font-medium text-gray-900">{{ roundNumbers(ursafPercentage) }} €</dd>
      </div>
      <div class="flex items-center justify-between pt-6 border-t border-gray-200">
        <dt class="text-sm">Bénéfice</dt>
        <dd class="text-sm font-medium text-gray-900">{{ roundNumbers(totalBenefit) }} €</dd>
      </div>
      <div class="flex items-center justify-between ">
        <dt class="text-base font-medium">Chiffre d'affaires</dt>
        <dd class="text-base font-medium text-gray-900">{{ roundNumbers(totalRevenue) }} €</dd>
      </div>

    </dl>
    <div class="px-4 py-6 border-t border-gray-200 sm:px-6">
      <button type="button" @click="postDataCreateSale"
              class="w-full px-4 py-3 text-base font-medium text-white border border-transparent rounded-full shadow-sm bg-slate-800 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-gray-50">
        Confirmer la vente
      </button>
    </div>
  </div>
  <div v-else class="py-5 mt-4 text-center bg-white border border-gray-200 rounded-lg shadow-sm">
    <h3 class="font-semibold text-16">Aucun produit sélectionné</h3>
  </div>
</template>

<script setup>
import {computed, defineProps} from 'vue';
import {TrashIcon} from '@heroicons/vue/20/solid';
import {calculeValuePourcentage, totalArray} from '@/utils/UtilsCacul';
import {roundNumbers} from '@/utils/RoundNumbers';
import {CreateSale} from "@/api/services/SalesServiceFunctions";
import {toast} from "vue-sonner";
import router from '@/router';
import authService from "@/api/services/authService";
import {formatDateTime} from "@/utils/DateSelectData";

const user = authService.getUser();

const props = defineProps({
  deleteProduct: Function,
  addMultipleProduct: Function,
  products: Array,
  realProduct: Array,
  salesChannelsDataCurrent: Array,
  productsBuyFormatPost: Array,
  name: String,
  date: String,
});

const postDataCreateSale = async () => {
  if (props.salesChannelsDataCurrent['@id'] && props.name !== "" && props.name !== " ") {
    try {
      const data = {
        "name": props.name,
        "canal": props.salesChannelsDataCurrent['@id'],
        "salesProducts": props.productsBuyFormatPost,
        "price": totalRevenue.value,
        "benefit": totalBenefit.value,
        "nbProduct": props.realProduct.length,
        "user": "api/users/" + user.id,
        "ursaf": ursafPercentage.value,
        "expense": totalExpense.value,
        "commission": commissionPercentage.value,
        "time": totalTime.value,
        "createdAt": formatDateTime(props.date),
      }

      const response = await CreateSale(data);
      if (response.status === 201) {
        await router.push({path: '/sales'})
      }
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  } else {
    toast.error("Vous devez remplir les champs demandés");

  }
};


const totalBenefit = computed(() =>
    totalArray(props.realProduct.map(item => item.price), 'benefit')
);

const totalRevenue = computed(() =>
    totalArray(props.realProduct.map(item => item.price), 'price')
);

const totalExpense = computed(() =>
    totalArray(props.realProduct.map(item => item.price), 'expense')
);
const totalTime = computed(() =>
    totalArray(props.realProduct.map(item => item.price), 'time')
);

const benefitPercentage = computed(() =>
    calculeValuePourcentage(totalRevenue.value, totalBenefit.value)
);

const commissionPercentage = computed(() =>
    // Remplacez ceci par le calcul réel si nécessaire
    props.realProduct.reduce((total, item) => total + (item.price.price * (item.price.commission / 100)), 0)
);

const ursafPercentage = computed(() => {
  // Récupérer les informations depuis le localStorage
  const typeOfUrssaf = localStorage.getItem('urssafType');
  // const batementPourcentValue = parseFloat(localStorage.getItem('abatementPourcent') || 0); // Assurez-vous que c'est un nombre
  const batementPourcentValue = 0; // Assurez-vous que c'est un nombre
  let result = 0;

  // Calculer en tenant compte de l'abattement
  if (typeOfUrssaf === '2') {
    result = props.realProduct.reduce((total, item) => {
      // Appliquer l'abattement si non nul
      const abatementFactor = (100 - batementPourcentValue) / 100; // Facteur de réduction basé sur l'abattement
      const adjustedPrice = item.price.price * abatementFactor; // Ajuster le prix selon l'abattement
      return total + (adjustedPrice * (item.price.ursaf / 100)); // Calculer la part URSSAF
    }, 0);
  } else {
    result = props.realProduct.reduce((total, item) => {
      // Appliquer l'abattement si non nul
      const abatementFactor = (100 - batementPourcentValue) / 100; // Facteur de réduction basé sur l'abattement
      const adjustedBenefit = item.price.benefit * abatementFactor; // Ajuster le bénéfice selon l'abattement
      return total + (adjustedBenefit * (item.price.ursaf / 100)); // Calculer la part URSSAF
    }, 0);
  }

  return result;
});

</script>
