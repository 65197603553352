<script setup>
import { ref } from 'vue'
import { VueFlow, useVueFlow } from '@vue-flow/core'
import DropzoneBackgroundFlow from './components/DropzoneBackgroundFlow.vue'
import SidebarFlow from './components/SidebarFlow.vue'
import useDragAndDrop from './components/useDnD'

const { onConnect, addEdges } = useVueFlow()
const { onDragOver, onDrop, onDragLeave, isDragOver } = useDragAndDrop()

const nodes = ref([])

onConnect(addEdges)
</script>

<template>
  <div class="w-screen h-screen">
    <div class="dnd-flow" @drop="onDrop" @dragover="onDragOver" @dragleave="onDragLeave">
      <VueFlow :nodes="nodes">
        <DropzoneBackgroundFlow :style="{
          backgroundColor: isDragOver ? '#e7f3ff' : 'transparent',
          transition: 'background-color 0.2s ease',
        }">
          <p v-if="isDragOver">Drop here</p>
        </DropzoneBackgroundFlow>
      </VueFlow>

      <SidebarFlow />
    </div>
  </div>
</template>
