<template>
  <DefaultAppLayout>
    <div v-if="isLoading || response !== null">
      <p v-if="isLoading" class="text-4xl">Loading</p>
      <div v-else v-html="response.data.choices[0].message.content" />
    </div>
    <div v-else>
      <div
          v-if="[1, 2, 3, 4, 5].includes(typeSubscription)"
          class="px-6 py-24 sm:py-32 lg:px-8">
        <div class="mx-auto w-full text-center">
          <h2 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Rapport détaillé des données de
            ventes</h2>
          <p class="mt-6 text-lg leading-8 text-gray-600">
            Accédez à une vue complète et approfondie de vos données de ventes avec notre rapport détaillé. Comparez
            facilement les performances de plusieurs périodes, identifiez les tendances et les opportunités de
            croissance.
            Grâce à l'IA avancée de Maker Copilot, obtenez des analyses précises et des recommandations personnalisées
            pour optimiser vos stratégies commerciales et maximiser vos résultats.
          </p>

          <fieldset>
            <RadioGroup v-model="selectedMailingLists" class="mt-6 flex space-x-2 text-start">
              <RadioGroupOption class="w-full" as="template" v-for="mailingList in mailingLists" :key="mailingList.id"
                                :value="mailingList" :aria-label="mailingList.title"
                                :aria-description="`${mailingList.description} to ${mailingList.users}`"
                                v-slot="{ active, checked }">
                <div
                    :class="[active ? 'border-purple-600 ring-2 ring-purple-600' : 'border-gray-300', 'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none']">
                <span class="flex flex-1">
                  <span class="flex flex-col">
                    <span class="block text-sm font-medium text-gray-900">{{ mailingList.title }}</span>
                    <span class="mt-1 flex items-center text-sm text-gray-500">{{ mailingList.description }}</span>
                  </span>
                </span>
                  <CheckCircleIcon :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-purple-600']"
                                   aria-hidden="true"/>
                  <span
                      :class="[active ? 'border' : 'border-2', checked ? 'border-purple-600' : 'border-transparent', 'pointer-events-none absolute -inset-px rounded-lg']"
                      aria-hidden="true"/>
                </div>
              </RadioGroupOption>
            </RadioGroup>
          </fieldset>
          <div class="flex flex-col space-y-2 mt-5">
            <div class="flex flex-col space-y-2 justify-start items-start w-full">
              <p class="text-lg text-slate-800">Date de la période</p>
              <DatePicker @dates="(dates) => { selectedDates.date1 = dates[0]; selectedDates.date2 = dates[1]; }"/>
            </div>
            <div>
              <p class="text-lg text-slate-800 text-left">Y a-t-il quelque chose de précis que vous aimeriez demander
                ?</p>
              <div class="mt-2">
            <textarea rows="4" v-model="messageUser"
                      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm/6"/>
              </div>
            </div>
          </div>

          <div class="mt-5 w-full">
            <DefaultActionBtn title="Générer le rapport" @click="pushPathRapport"/>
          </div>
        </div>
      </div>
      <SubMessage v-else/>
    </div>
  </DefaultAppLayout>
</template>

<script setup>
import {ref} from 'vue';
import authService from '@/api/services/authService';
import SubMessage from "@/components/SubMessage.vue";
import DefaultAppLayout from '@/components/Layout/DefaultAppLayout.vue';
import DatePicker from '@/components/Button/DatePicker.vue';
import {RadioGroup, RadioGroupOption} from '@headlessui/vue';
import {CheckCircleIcon} from '@heroicons/vue/20/solid';
import DefaultActionBtn from "@/components/Button/DefaultActionBtn.vue";
import {toast} from "vue-sonner";
import axios from "@/api/config";

const user = authService.getUser();
const typeSubscription = ref(user['type_subscription']);
const messageUser = ref('');
const response = ref(null);
const isLoading = ref(false);
const selectedDates = ref({
  date1: null,
  date2: null,
});

const mailingLists = [
  {
    id: 1,
    title: 'Sales Analysis',
    description: 'Analyse en profondeur des tendances de vente, incluant saisonnalité, produits les plus vendus, et clients actifs. Identifie les périodes de vente forte/faible et propose des stratégies d’optimisation',
  },
  {
    id: 2,
    title: 'Product Performance',
    description: 'Étude de la rentabilité de chaque produit, avec des suggestions d’ajustement des prix, de réduction des coûts et d’optimisation des marges. Examine les catégories pour repérer celles avec le meilleur potentiel.',
  },
  {
    id: 3,
    title: 'Customer Insights',
    description: 'Analyse des segments clients, identification des clients les plus rentables, et recommandations pour la fidélisation et l\'upselling. Compare les marges générées par client et leur fréquence d’achat.',
  }, {
    id: 4,
    title: 'Channel Analysis',
    description: 'Évaluation des performances par canal de vente, avec une attention aux marges, aux commissions et aux coûts associés. Recommande des actions pour maximiser le potentiel des canaux performants',
  },
  {
    id: 5,
    title: 'Profitability Strategy',
    description: 'Recommandations de stratégie pour augmenter les bénéfices, incluant des projections de scénarios pour ajuster les prix, commissions, et volumes de vente. Fournis des conseils de tarification et des ajustements pour optimiser la rentabilité globale.',
  },
];

const selectedMailingLists = ref(mailingLists[0]);

const pushPathRapport = async () => {
  isLoading.value = true;
  if (!selectedMailingLists.value.id || !selectedDates.value.date1 || !selectedDates.value.date2) {
    toast.error("Veuillez remplir tous les champs obligatoires avant de continuer.");
  }

  response.value = await axios.post("/rapport/data", {
    "date1": selectedDates.value.date1.split(" ")[0],
    "date2": selectedDates.value.date2.split(" ")[0],
    "focus": selectedMailingLists.value.title,
    "message": messageUser.value
  })

  console.log({
    "date1": selectedDates.value.date1,
    "date2": selectedDates.value.date2,
    "focus": selectedMailingLists.value.title,
    "message": messageUser.value
  })
  console.log(response.value)

  isLoading.value = false
};


</script>
