import { GetCallApiParams } from "@/api/services/utilsApi";
import authService from "./authService";

// Generic function to get data with axios params
const getData = async (endpoint, filter) => {
  const userId = authService.getUser().id;
  const params = { user_id: userId, ...filter };
  return await GetCallApiParams(endpoint, {
    params,
  });
};

/* ---------------------------- Data Fetching Functions ---------------------------- */

export const getAllViewData = async (endpoint, filter) => {
  return await getData(endpoint, filter);
};
export const GetAllViewCanalMonth = async (filter) =>
  await getData("/view_canal_months", filter);

export const GetAllViewBenefitMonth = async (filter) =>
  await getData("/view_benefit_months", filter);

export const GetAllViewBenefitYear = async (filter) =>
  await getData("/view_benefit_years", filter);

export const GetBestProductSalesMonth = async (filter) =>
  await getData("/view_best_product_sales_months", filter);

export const GetBestProductSalesYear = async (filter) =>
  await getData("/view_best_product_sales_years", filter);

/* --------------------------------- Category --------------------------------- */

export const GetAllViewBenefitMonthCategory = async (filter) =>
  await getData("/view_benefit_month_categories", filter);

export const GetAllViewBenefitYearCategory = async (filter) =>
  await getData("/view_benefit_year_categories", filter);

export const GetAllViewCanalMonthCategory = async (filter) =>
  await getData("/view_canal_month_categories", filter);

export const GetAllViewCanalYearCategory = async (filter) =>
  await getData("/view_canal_year_categories", filter);

export const GetBestProductSalesYearCategory = async (filter) =>
  await getData("/view_best_product_sales_year_categories", filter);

export const GetBestProductSalesMonthCategory = async (filter) =>
  await getData("/view_best_product_sales_month_categories", filter);

/* --------------------------------- Client --------------------------------- */
export const GetAllViewBenefitMonthClient = async (filter) =>
  await getData("/view_benefit_month_clients", filter);

export const GetAllViewBenefitYearClient = async (filter) =>
  await getData("/view_benefit_year_clients", filter);

export const GetBestProductSalesYearClient = async (filter) =>
  await getData("/view_best_product_sales_year_clients", filter);

export const GetBestProductSalesMonthClient = async (filter) =>
  await getData("/view_best_product_sales_month_clients", filter);

/* --------------------------------- Canal --------------------------------- */
export const GetAllViewBenefitMonthCanal = async (filter) =>
  await getData("/view_benefit_month_canals", filter);

export const GetAllViewBenefitYearCanal = async (filter) =>
  await getData("/view_benefit_year_canals", filter);

export const GetBestProductSalesYearCanal = async (filter) =>
  await getData("/view_best_product_sales_year_canals", filter);

export const GetBestProductSalesMonthCanal = async (filter) =>
  await getData("/view_best_product_sales_month_canals", filter);

/* --------------------------------- Product --------------------------------- */
export const GetAllViewCanalMonthProduct = async (filter) =>
  await getData("/view_canal_month_products", filter);

export const GetAllViewBenefitMonthProduct = async (filter) =>
  await getData("/view_benefit_month_products", filter);

export const GetAllViewCanalYearProduct = async (filter) =>
  await getData("/view_canal_year_products", filter);

export const GetAllViewBenefitYearProduct = async (filter) =>
  await getData("/view_benefit_year_products", filter);
