<template>
    <DefaultBox customClass="w-full xl:h-[500px] flex flex-col space-y-3">
        <h3 class="mt-2 font-bold tracking-tight text-gray-900 text-16">Information sur votre type d'urssaf</h3>
        <div v-if="userData != null">
            <fieldset>
                <legend class="sr-only">Type d'imposition</legend>
                <div class="space-y-5">
                    <!-- Section Bénéfice -->
                    <div class="relative flex items-start">
                        <div class="flex items-center h-6">
                            <!-- Checkbox Bénéfice -->
                            <input id="comments" aria-describedby="comments-description" name="comments" type="checkbox"
                                v-model="dataSelectBenefit"
                                class="w-4 h-4 text-purple-600 border-gray-300 rounded focus:ring-purple-600" />
                        </div>
                        <div class="ml-3 text-sm leading-6">
                            <!-- Label Bénéfice -->
                            <label for="comments" class="font-medium text-gray-900">Bénéfice</label>
                            <!-- Description Bénéfice -->
                            <p id="comments-description" class="text-gray-500">
                                Le calcul de vos cotisations sociales s'effectue sur le
                                <span class="font-semibold">bénéfice</span> si vous êtes une
                                <span class="font-semibold">société</span>. Cela signifie que vos cotisations sont
                                basées
                                sur
                                le profit net après déduction de toutes les charges et dépenses.
                            </p>
                        </div>
                    </div>

                    <!-- Section Chiffre d'Affaire -->
                    <div class="relative flex items-start">
                        <div class="flex items-center h-6">
                            <!-- Checkbox Chiffre d'Affaire -->
                            <input id="offers" aria-describedby="offers-description" name="offers" type="checkbox"
                                v-model="dataSelectCa"
                                class="w-4 h-4 text-purple-600 border-gray-300 rounded focus:ring-purple-600" />
                        </div>
                        <div class="ml-3 text-sm leading-6">
                            <!-- Label Chiffre d'Affaire -->
                            <label for="offers" class="font-medium text-gray-900">Chiffre d'affaire</label>

                            <!-- Description Chiffre d'Affaire -->
                            <p id="offers-description" class="text-gray-500">
                                Le calcul de vos cotisations sociales s'effectue sur le
                                <span class="font-semibold">chiffre d'affaires</span> si vous êtes
                                <span class="font-semibold">auto-entrepreneur</span>. Cela signifie que vos cotisations
                                sont
                                basées sur
                                le total des ventes ou des services facturés sans déduction des charges.
                            </p>
                        </div>
                    </div>
                </div>
            </fieldset>
            <form @submit.prevent="saveData" class="space-y-3">
                <div>
                    <label for="value_urssaf" class="block text-sm font-medium leading-6 text-gray-900">Valeur d'urssaf
                        par
                        défaut (en %)</label>
                    <div class="mt-2">
                        <input type="number" step="0.1" name="value_urssaf" id="value_urssaf" v-model="valueUrssaf"
                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6" />
                    </div>
                </div>
<!--                <div>
                    <label for="value_urssaf" class="block text-sm font-medium leading-6 text-gray-900">Valeur
                        Abattement forfaitaire micro-fiscal (en %)</label>
                    <div class="mt-2">
                        <input type="number" step="0.1" name="value_abatement_pourcent" id="value_abatement_pourcent"
                            v-model="valueAbatementPourcent"
                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6" />
                    </div>
                </div>-->
                <DefaultSubmitBtn :isDisabled="buttonDisable">Sauvegarder</DefaultSubmitBtn>
            </form>
        </div>
        <div v-else class="space-y-3">
            <SkeletonLine customClass="w-full h-32" />
            <SkeletonLine customClass="w-full h-32" />
            <SkeletonLine customClass="w-full h-32" />
        </div>
    </DefaultBox>
</template>


<script setup>
import { ref, watch, onMounted } from "vue";

import DefaultSubmitBtn from "@/components/Button/DefaultSubmitBtn.vue"
import DefaultBox from '@/components/Layout/DefaultBox.vue'
import SkeletonLine from "@/components/Ui/SkeletonLine.vue";

import { GetUserById, UpdateUser } from '@/api/services/UserServiceFunctions'
import authService from "@/api/services/authService";

const dataSelectBenefit = ref(false)
const dataSelectCa = ref(false)
const valueUrssaf = ref(null)
const valueAbatementPourcent = ref(null)
const buttonDisable = ref(1)
const userData = ref(null)

watch([dataSelectBenefit, dataSelectCa, valueUrssaf], ([newDataSelectBenefit, newDataSelectCa, newDalueUrssaf]) => {
    if ((newDataSelectBenefit || newDataSelectCa) && newDalueUrssaf) {
        buttonDisable.value = 0;
    }
});

// Watcher pour dataSelectBenefit
watch(dataSelectBenefit, (newValue) => {
    if (newValue) {
        dataSelectCa.value = false;
    }
});

// Watcher pour dataSelectCa
watch(dataSelectCa, (newValue) => {
    if (newValue) {
        dataSelectBenefit.value = false;
    }
});

const getData = async () => {
    const userDataResponse = await GetUserById(authService.getUser().id)
    userData.value = userDataResponse
    valueUrssaf.value = userDataResponse?.urssafPourcent
    valueAbatementPourcent.value = userDataResponse?.abatementPourcent

    if (userDataResponse?.urssafType === 1) {
        dataSelectBenefit.value = true
    } else if (userDataResponse?.urssafType === 2) {
        dataSelectCa.value = true
    }
}

const saveData = () => {
    try {
        UpdateUser(authService.getUser().id, {
            urssafType: dataSelectBenefit.value ? 1 : 2,
            urssafPourcent: valueUrssaf.value,
            abatementPourcent: valueAbatementPourcent.value,
        })
        localStorage.setItem("urssafType", dataSelectBenefit.value ? 1 : 2);
        localStorage.setItem("urssafPourcent", valueUrssaf.value);
        localStorage.setItem("abatementPourcent", valueAbatementPourcent.value);
    } catch (error) {
        console.error(error)
    }
}

onMounted(() => {
    getData()
})
</script>
