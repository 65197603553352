import { GetCallApiParams } from "@/api/services/utilsApi";

export const GetRapportData = async (date1, date2, date3, date4) => {
  return await GetCallApiParams("/rapport/data", {
    date1: date1,
    date2: date2,
    date3: date3,
    date4: date4,
  });
};

