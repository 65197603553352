<template>
    <ul v-if="canalMonth" role="list" class="h-full divide-y divide-white/5">
        <RouterLink :to="`/canal/${item.canal_id}`" v-for="(item, index) in canalMonth" :key="index" class="relative flex items-center py-2 space-x-4">
            <div
                :class="[environments[index + 1], 'flex-none rounded-full h-7 w-7 flex justify-center items-center text-10 font-medium ring-1 ring-inset']">
                {{ index + 1 }}</div>
            <div class="flex-auto min-w-0">
                <div class="flex items-center gap-x-3">
                    <h2 class="min-w-0 font-semibold leading-6 text-13 text-slate-800">
                        <a :href="item.href" class="flex gap-x-2">
                            <span class="truncate">{{ item.name }}</span>
                        </a>
                    </h2>
                </div>
                <div class="flex items-center gap-x-2.5 text-10 leading-5 text-slate-600">
                    <p class="truncate">{{ item.nb_product_value }} articles vendus</p>
                    <svg viewBox="0 0 2 2" class="h-0.5 w-0.5 flex-none fill-gray-300">
                        <circle cx="1" cy="1" r="1" />
                    </svg>
                    <p class="whitespace-nowrap">{{ roundNumbers(item.price_value )}} € de CA pour {{ roundNumbers(item.benefit_value) }} € de bénéfice</p>
                </div>
            </div>
            <ChevronRightIcon class="flex-none w-5 h-5 text-slate-600" aria-hidden="true" />
        </RouterLink>
    </ul>
    <div v-else class="flex flex-col justify-between h-full mt-3">
        <SkeletonLine customClass="w-full h-14" />
        <SkeletonLine customClass="w-full h-14" />
        <SkeletonLine customClass="w-full h-14" />
        <SkeletonLine customClass="w-full h-14" />
        <SkeletonLine customClass="w-full h-14" />
    </div>
</template>

<script setup>
import { defineProps } from 'vue';
import { ChevronRightIcon } from '@heroicons/vue/20/solid'
import SkeletonLine from '@/components/Ui/SkeletonLine.vue'
import { roundNumbers } from '@/utils/RoundNumbers';
import { RouterLink } from 'vue-router';

defineProps({
    canalMonth: Array
})

const environments = {
    1: 'text-yellow-600 bg-yellow-400/10 ring-yellow-400/20',
    2: 'text-slate-400 bg-slate-400/10 ring-slate-400/30',
    3: 'text-amber-400 bg-amber-400/10 ring-amber-400/30',
    4: 'text-orange-400 bg-orange-400/10 ring-orange-400/30',
    5: 'text-zinc-400 bg-zinc-400/10 ring-zinc-400/30',
}
</script>