<template>
    <TransitionRoot as="template" :show="open">
        <Dialog class="relative z-50" @close="open = false">
            <SubModal />
        </Dialog>
    </TransitionRoot>
    <DefaultAppLayout>
        <div class="p-5">
            <Combobox @update:modelValue="onSelect">
                <div class="flex xl:flex-row flex-col xl:items-center items-start justify-between">
                    <h2 class="font-bold tracking-tight text-gray-900 text-22 xl:mb-0 mb-5">Tous vos produits</h2>
                    <div class="flex flex-row items-end space-x-3">
                        <SwitchGroup as="div" class="flex items-center">
                            <Switch v-model="isDisplayArchived"
                                :class="[isDisplayArchived ? 'bg-c_purple' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-c_purple focus:ring-offset-2']">
                                <span aria-hidden="true"
                                    :class="[isDisplayArchived ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                            </Switch>
                            <SwitchLabel as="span" class="ml-3 text-sm">
                                <span class="block xl:hidden font-medium text-gray-900">Afficher les produits archivé</span>
                            </SwitchLabel>
                        </SwitchGroup>
                        <div v-if="products">
                            <DefaultLinkBtn v-if="isCreateProduct" to="/create-product" title="Créer un produit" />
                            <button v-else @click="open = true"
                                class="rounded-md px-3 py-2 text-sm font-semibold bg-c_light_gray hover:bg-c_gray border-slate-800 border text-slate-800">
                                Créer un produit</button>
                        </div>
                    </div>
                </div>
                <ComboboxInput
                    class="w-full mt-3 rounded-md border-0 bg-c_smoke_white px-4 py-2.5 text-gray-900 focus:ring-0 text-lg"
                    placeholder="Rechercher un produit..." @change="query = $event.target.value" @blur="query = ''" />
                <ComboboxOptions v-if="filteredProducts.length > 0" static
                    class="grid grid-cols-1 mt-5 gap-x-3 gap-y-8 xl:gap-y-4 xl:grid-cols-4 xl:gap-x-4">
                    <ComboboxOption v-for="product in filteredProducts" :key="product.id">
                        <DefaultProductCard :product="product" :isDisplayArchived="isDisplayArchived" />
                    </ComboboxOption>
                </ComboboxOptions>
                <div v-else class="grid grid-cols-1 mt-5 gap-x-3 gap-y-8 lg:grid-cols-4 xl:gap-x-4">
                    <SkeletonLine customClass="w-full h-36" />
                    <SkeletonLine customClass="w-full h-36" />
                    <SkeletonLine customClass="w-full h-36" />
                    <SkeletonLine customClass="w-full h-36" />
                    <SkeletonLine customClass="w-full h-36" />
                </div>
                
                <div v-if="query !== '' && filteredProducts.length === 0" class="px-4 py-14 text-center sm:px-14">
                    <UsersIcon class="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                    <p class="mt-4 text-sm text-gray-900">Aucun élément trouvé..</p>
                </div>
               
            </Combobox>
        </div>
    </DefaultAppLayout>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import {
    Switch,
    SwitchGroup,
    SwitchLabel,
    TransitionRoot,
    Dialog,
    Combobox,
    ComboboxInput,
    ComboboxOptions,
} from '@headlessui/vue'

import authService from '@/api/services/authService';

const user = authService.getUser();

import { UsersIcon } from '@heroicons/vue/24/outline'

import DefaultAppLayout from '@/components/Layout/DefaultAppLayout.vue'
import DefaultLinkBtn from '@/components/Button/DefaultLinkBtn.vue'
import DefaultProductCard from '@/components/Other/DefaultProductCard.vue';
import { GetAllProductData } from "@/api/services/ProductServiceFunctions";
import SkeletonLine from '@/components/Ui/SkeletonLine.vue'
import SubModal from '@/components/Modal/SubModal.vue';

const products = ref([]);
const isCreateProduct = ref(false);
const isDisplayArchived = ref(false); // Si à false, n'affiche pas les produits archivés
const open = ref(false);
const query = ref('');

const filteredProducts = computed(() => {
    // Filtre de base sur les produits en fonction de la recherche dans query
    let filteredProducts = query.value === ''
        ? products.value
        : products.value.filter((product) => {
            return product.name.toLowerCase().includes(query.value.toLowerCase());
        });

    // Ensuite, appliquez le filtre basé sur l'archivage
    if (!isDisplayArchived.value) {
        // Si `isDisplayArchived` est à false, on filtre les produits archivés
        filteredProducts = filteredProducts.filter(product => !product.isArchived);
    }

    return filteredProducts;
});

const onSelect = (product) => {
    if (product) {
        window.location = product.url
    }
}

const fetchProducts = async () => {
    const data = await GetAllProductData();
    if (data) {
        subscriptionValidation(data)
        products.value = data.map(product => ({
            ...product,
            // imageSrc: product.imageSrc || 'https://redthread.uoregon.edu/files/original/affd16fd5264cab9197da4cd1a996f820e601ee4.png', // Remplacez par l'URL par défaut de l'image si nécessaire
            // imageAlt: product.imageAlt || 'Product image',
            href: `/product/${product.id}`, // Lien vers la page produit détaillée
        }));
    }
};

const subscriptionValidation = (data) => {
    const typeSubscription = user['type_subscription'];

    if (typeSubscription == 2 || typeSubscription == 4 || typeSubscription == 5) {
        isCreateProduct.value = true
    } else if (data.length >= 5 && typeSubscription == 0) {
        isCreateProduct.value = false
    } else if (data.length >= 10 && (typeSubscription == 1 || typeSubscription == 3)) {
        isCreateProduct.value = false
    } else {
        isCreateProduct.value = true
    }

}

onMounted(() => {
    fetchProducts();
});
</script>
