<template>
    <div>
        <canvas ref="doughnutChart"></canvas>
        <div class="relative">
            <p
                class="absolute font-semibold text-42 text-slate-800 top-[-150px] left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              {{ numberSapce(roundNumbers(benefitMonth[0].price_value, 0)) }}{{ roundNumbers(benefitMonth[0].price_value, 0).toString().length < 4 ? ' €' : '' }}
            </p>
            <p
                class="absolute text-lg text-center text-slate-800 top-[-100px] left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <span class="font-semibold">{{ calculeValuePourcentage(Number(valueObjectif),
                    benefitMonth[0].price_value) }}%</span> de votre objectif de <span class="font-semibold">{{
                        numberSapce(valueObjectif) }}€</span>
            </p>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, defineProps } from 'vue';
import { Chart, ArcElement, DoughnutController, Legend, Tooltip } from 'chart.js';
import { roundNumbers } from '@/utils/RoundNumbers';
import { numberSapce } from '@/utils/DataFormater';
import { calculeValuePourcentage } from '@/utils/UtilsCacul';

Chart.register(ArcElement, DoughnutController, Legend, Tooltip);

const props = defineProps({
    benefitMonth: Array,
    categoryMonth: Array
})

const doughnutChart = ref(null);
const valueObjectif = ref(localStorage.getItem("objectifValue") || 1000)

const createDoughnutChart = () => {
    const ctx = doughnutChart.value.getContext('2d');
    const data = {
        labels: props.categoryMonth.map((item) => item.name),
        datasets: [
            {
                data: props.categoryMonth.map((item) => item.price_value),
                backgroundColor: ["#E1BEF6", "#E1FF72", "#E3F5FF", "#EFEFED", "#EFEDDD"],
                borderRadius: 20,
                borderWidth: 5,
                borderDash: [],
                borderStyle: 'dash',
                cutoutPercentage: '10%',
                line: { borderWidth: '10%' }
            },
        ],
    };

    const options = {
        cutout: 120,
        aspectRatio: 1.3,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    // Modify the label displayed in the tooltip
                    label: (tooltipItem) => {
                        const value = tooltipItem.raw; // Get the raw value
                        return `  ${value} €`; // Append Euro symbol to the value
                    },
                },
            },
        }
    };

    // Use Chart constructor and specify 'doughnut' as the type
    new Chart(ctx, {
        type: 'doughnut', // Specify the type of chart
        data: data,
        options: options
    });
};

onMounted(() => {
    createDoughnutChart();
});
</script>

<style scoped>
canvas {
    max-width: 400px;
    max-height: 400px;
}
</style>